<template>
  <!-- <div class="container-card">
    <v-toolbar dark color="primary" class="card-header">
      <h2 class="text-h5">{{ prepareTitle() }} {{ prepareDate()}}</h2>
    </v-toolbar>
    <div class="h-100 d-flex justify-content-center flex-column" v-if="getPDF">
      <iframe :src="pdfsrc"
              frameborder="0"
              scrolling="yes"
              marginheight="0"
              marginwidth="0"
              class="pdf">
      </iframe>
    </div>
    <div class="container-msg-error" v-else>
      <h1 class="msg-error">Tivemos um problema ao carregar este arquivo.</h1>
      <v-spacer></v-spacer>
      <div class="container-img">
        <router-link to="/" class="pt-2">
          <LogoImage pWidthLogo="15vw"
                     pMaxWidthLogo="360"
                     :subtEnabled="false"
                     to="/" />
        </router-link>
      </div>
    </div>
  </div> -->

  <div class="Display">
    <Loading :pLoading="loading" />

    <DialogError :errorDialog="errored"
      title="Parece que estamos com alguns problemas"
      message="Não foi possível carregar esta página agora. Tente novamente mais tarde..." />

    <NavComponent></NavComponent>
    <v-container>
      <div class="d-flex flex-column">

        <div class="logoSearch d-flex align-items-center justify-content-center flex-wrap">
          <router-link to="/" class="pt-2">
            <LogoImage pWidthLogo="14vw"
              pMaxWidthLogo="204"
              :subtEnabled="false" />
          </router-link>

          <v-flex grow class="inputAdvancedSearch">
            <SearchBar></SearchBar>
          </v-flex>

        </div>
        <div id="main">
          <ItemDisplay :ato="constituicao" />
        </div>
      </div>  
    </v-container>
    <Footer />
  </div>
</template>

<script>
    import {
        prepareTitle as utilPrepareTitle,
        prepareDate as utilPrepareDate,
    } from '@/utils/prepareTextAto.js';
    import NavComponent from '@/shared/components/NavComponent.vue';
    import SearchBar from '@/shared/components/SearchBar.vue';
    import LogoImage from '@/shared/components/LogoImage.vue';
    import ItemDisplay from './Display.vue';
    import Loading from '@/shared/components/Loading.vue';
    import DialogError from '@/shared/components/DialogError.vue';
    import Footer from '@/shared/components/Footer.vue';

    
    export default {
        name: 'CardConstituicao',
        data: () => {
            return ({
                active: false,
                constituicao: null,
                pdfsrc: "",
                errored: false,
                loading: false,
                loadpdf: false
            })
        },
        components: {
          NavComponent,
          SearchBar,
          ItemDisplay,
          LogoImage,
          Loading,
          DialogError,
          Footer
        },
        computed: {
          getPDF() {
            this.tryPDF();
            return this.loadpdf;
          }
        },
        methods: {
          tryPDF: function () {
            if (this.preparePDF()) {
              this.$http.get(this.pdfsrc)
              .then(() => { 
                this.loadpdf = true 
              })
              .catch(err => { 
                if (err.message.includes('404')) this.loadpdf = false;
                else this.loadpdf = true;
              });
            } 
            else this.loadpdf = false;
          },
          preparePDF: function () {
              if (this.constituicao) {
                this.pdfsrc = `${this.$apiURL}/uploads/constituicao/${this.constituicao.id}/${this.constituicao.arquivo}`;
                return true;
              } 
              else return false;
          },
          prepareTitle: function () {
            if (this.constituicao) return utilPrepareTitle(this.constituicao.descricao, "").replace("n°", "");
          },
          prepareDate: function () {
            if (this.constituicao) return `de ${utilPrepareDate(this.constituicao.dataAto)}`;
              
          },
          getConstituicao: function () {
            this.$http.get('/Public/GetConstituicao')
            .then(res => {
              this.active = true;
              this.constituicao = res.data;
              this.preparePDF();
            })
            .catch(err => {
              console.log(err);
              this.active = false;
            });
          }
        },
        mounted() {
          this.getConstituicao();
        }
    }
</script>

<style>
    @import './Constituicao.css';
</style>
