<template>
  <div id="Sidebar" class="d-flex flex-row flex-md-column justify-content-between justify-content-lg-start mb-lg-3">
    <!--<div v-if="icons[0].active"
         class="iconButton">

      <v-dialog max-width="400"
                v-model="emailDialog"
                :persistent="emailPersistent">

        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-3 mx-md-0"
                 icon
                 color="transparent"
                 elevation="0"
                 :disabled="pDisabled"
                 v-bind="attrs"
                 title="Enviar por e-mail"
                 v-on="on">
            <v-icon large color="#3D3B3B">{{ icons[0].icon }}</v-icon>
          </v-btn>
        </template>

         <template v-slot:default="dialog">
          <DialogEmail v-on:close-dialog="dialog.value = false"
                       :pShowForm="emailShowForm"
                       @sending-email="(varPersistent) => emailPersistent = varPersistent"
                       @show-form="(varShowForm) => emailShowForm = varShowForm" />
        </template>

      </v-dialog>
    </div>-->

    <div v-if="icons[1].active"
         class="iconButton">
      <v-btn class="mx-3 mx-md-0"
             icon
             color="transparent"
             elevation="0"
             title="PDF Original"
             :disabled="isDisabledPdf"
             v-on:click="show('pdf')">
        <!--<a :href="pdfsrc" target="_blank" rel="noopener noreferrer">-->
        <v-icon large color="#3D3B3B">{{icons[1].icon}}</v-icon>
      </v-btn>
    </div>

    <div v-if="icons[2].active"
         class="iconButton">
      <v-btn class="mx-3 mx-md-0"
             icon
             color="transparent"
             elevation="0"
             title="Texto Original"
             :disabled="downloadOtptionEnabled || pDisabled"
             v-on:click="show('html')">
        <v-icon large color="#3D3B3B">{{icons[2].icon}}</v-icon>
      </v-btn>
    </div>

    <div v-if="icons[5].active"
         class="iconButton">
      <v-btn class="mx-3 mx-md-0"
             icon
             color="transparent"
             elevation="0"
             title="PDF Atualizado"
             :disabled="isDisabledPdfCons"
             v-on:click="show('pdfc')">
        <!--<a :href="pdfsrccons" target="_blank" rel="noopener noreferrer">-->
        <v-icon large color="#3D3B3B">{{icons[5].icon}}</v-icon>
      </v-btn>
    </div>

    <div v-if="icons[6].active"
         class="iconButton">
      <v-btn class="mx-3 mx-md-0"
             icon
             color="transparent"
             elevation="0"
             title="Texto Atualizado"
             :disabled="downloadConsOtptionEnabled || pconsDisabled"
             v-on:click="show('htmlc')">
        <v-icon large color="#3D3B3B">{{icons[6].icon}}</v-icon>
      </v-btn>
    </div>

    <div v-if="icons[3].active"
         class="iconButton">
      <v-dialog max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-3 mx-md-0"
                 icon
                 color="transparent"
                 elevation="0"
                 :disabled="pDisabled"
                 title="Mais informações sobre o Ato"
                 v-bind="attrs"
                 v-on="on">
            <v-icon large color="#3D3B3B">{{icons[3].icon}}</v-icon>
          </v-btn>
        </template>

        <template v-slot:default="dialog">
          <DialogInfo :ato="ato"
                      v-on:close-dialog="dialog.value = false" />
        </template>
      </v-dialog>
    </div>
    <div v-if="icons[4].active"
         class="iconButton">
      <v-dialog max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-3 mx-md-0"
                 icon
                 color="transparent"
                 elevation="0"
                 :disabled="true"
                 title="Histórico"
                 v-bind="attrs"
                 v-on:click="$router.push('/visualizar/constituicao/historico?id=' + ato.id)">
            <!--<v-icon large color="#3D3B3B">{{icons[4].icon}}</v-icon>-->
            <span aria-hidden="true" class="v-icon notranslate theme--light" style="font-size: 36px; height: 36px; width: 36px; color: rgb(61, 59, 59); caret-color: rgb(61, 59, 59);">
              <svg style="width:36px;height:36px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M20 2H2V8H20V2M18 6H4V4H18V6M16 9C14.69 9 13.46 9.37 12.41 10H8.5C8.22 10 8 10.22 8 10.5V12H10.26C9.47 13.13 9 14.5 9 16C9 16.7 9.11 17.37 9.29 18H5V9H3V20H10.26C11.53 21.81 13.62 23 16 23C19.87 23 23 19.87 23 16C23 12.13 19.87 9 16 9M16 21C13.24 21 11 18.76 11 16S13.24 11 16 11 21 13.24 21 16 18.76 21 16 21M16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25Z" />
              </svg>
            </span>
          </v-btn>
        </template>

        <template v-slot:default="dialog">
          <DialogInfo data-app :ato="ato"
                      v-on:close-dialog="dialog.value = false" />
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
    import DialogInfo from './DialogInfo.vue';
    //import DialogEmail from './DialogEmail.vue';

    import { mdiEmail } from '@mdi/js';
    import { mdiFileDocument } from '@mdi/js';
    import { mdiInformation } from '@mdi/js';
    import { mdiArchiveClock } from '@mdi/js';
    import { mdiFileDocumentOutline } from '@mdi/js';
    import { mdiFileRestore } from '@mdi/js';
    import { mdiFileRestoreOutline } from '@mdi/js';

    export default {
        name: 'Sidebar',
        data: () => {
            return ({
                emailDialog: false,
                emailShowForm: true,
                emailPersistent: false,
                icons: [
                    {
                        name: "mail",
                        icon: mdiEmail,
                        active: false,
                    },
                    {
                        name: "pdf",
                        icon: mdiFileDocument,
                        active: true,
                    },
                    {
                        name: "html",
                        icon: mdiFileDocumentOutline,
                        active: true,
                    },
                    {
                        name: "info",
                        icon: mdiInformation,
                        active: true,
                    },
                    {
                        name: "history",
                        icon: mdiArchiveClock,
                        active: false
                    },
                    {
                        name: 'pdfcons',
                        icon: mdiFileRestore,
                        active: true,
                    },
                    {
                        name: 'htmlcons',
                        icon: mdiFileRestoreOutline,
                        active: true,
                    }
                ]
            })
        },
        props: {
            pDisabled: {
                type: Boolean,
                default: true,
            },
            histDisabled: {
              type: Boolean,
              default: true,
            },
            pconsDisabled: {
              type: Boolean,
              default: true,
            },
            ato: Object,
            pdfsrc: String,
            pdfsrccons: String,
            downloadOtptionEnabled: Boolean,
            downloadConsOtptionEnabled: Boolean,
        },
        components: {
            DialogInfo,
            //DialogEmail,
        },
        methods: {
            show: function (where) {
                this.$emit('change-show', where);
            },
            navigatePdfURL: function () {
                window.location = this.pdfsrc;
            },
            navigatePdfcURL: function () {
              window.location = this.pdfsrccons;
            }
        },
        computed: {
            isDisabledPdf() {
                return this.pDisabled || !this.ato.arquivo;
            },
            isDisabledPdfCons() {
              return this.pconsDisabled || !this.ato.arquivoAtualizado;
            },
            isDisabledHtml() {
                return this.pDisabled || !this.ato.texto;
            },
            isDisabledHtmlCons() {
              return this.pconsDisabled || !this.ato.texto;
            },
        },
        watch: {
            emailDialog(val) {
                !val && (this.emailShowForm = true);
            }
        }
    }
</script>

<style>
    @import './Sidebar.css';
</style>
