<template>
    <div data-app class="d-flex flex-nowrap flex-column flex-md-row" id="dFlexItemDisplayConst" v-if="ato">
        <div id="SidebarContainer">
            <Sidebar :pDisabled="!hasConteudo"
              :histDisabled="false"
              :pconsDisabled="!hasConsolidado"
              :ato="ato"
              :pdfsrc="pdfsrc"
              :pdfsrccons="pdfsrccons"
              @change-show="changeShow"
              :downloadOtptionEnabled="downloadOtptionEnabled"
              :downloadConsOtptionEnabled="downloadConsOtptionEnabled"></Sidebar>
        </div>

        <div id="ItemDisplayContainerConst" class="d-flex flex-column flex-grow-1">
            <div id="TopbarContainerConst" class="d-flex flex-row align-items-center pl-4">
                <v-img :src="require(`../../assets/imgs/logodecreto.png`)"
                  alt="Logo do Governo do Estado"
                  contain
                  max-height="46"
                  max-width="37" />

                <div class="d-flex flex-column flex-md-row align-items-baseline py-2">
                  <h2 id="h2TitleConst" class="text-left ml-4">{{ prepareTitle }}</h2>
                  <h3 id="h3DataConst" class="text-left ml-md-3 mx-4">{{ prepareDate }}</h3>
                </div>
            </div>

            <div id="ItemDisplayConst" class="d-flex justify-content-center flex-column" v-if="hasConteudo || hasConsolidado">
              <div class="d-flex justify-content-left">
                <h2 id="h2TitleConst" v-if="showing === 'htmlc' || showing === 'pdfc'">Texto Atualizado</h2>

                <h2 id="h2TitleConst" v-else>Texto Original</h2>

                <div v-if="showing === 'pdfc' || showing === 'pdf'" class="my-auto">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a :href="showing === 'pdf' ? pdfsrc : showing === 'pdfc' ? pdfsrccons : '/notfound'" target="_blank" class="my-auto ml-2" v-bind="attrs" v-on="on"><v-icon>{{ newtab }}</v-icon></a>
                    </template>

                    <span>Abrir PDF em nova aba.</span>
                  </v-tooltip>
                </div>

                <div v-else class="my-auto">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a :href="showing === 'html' ? preparePath : showing === 'htmlc' ? preparePathCons : '/notfound'" target="_blank" class="my-auto ml-2" v-bind="attrs" v-on="on"><v-icon>{{ newtab }}</v-icon></a>
                    </template>

                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </div>
              </div>

              <hr />

              <iframe v-if="showing === 'pdfc'"
                :src="pdfsrccons"
                frameborder="0"
                scrolling="yes"
                marginheight="0"
                marginwidth="0" />

              <iframe v-if="showing === 'htmlc'"
                :srcdoc="renderhtmlcons"
                v-html="renderhtmlcons"
                frameborder="0"
                scrolling="yes"
                marginheight="0"
                marginwidth="0" />

              <iframe v-if="showing === 'html'"
                :srcdoc="renderhtml"
                v-html="renderhtml"
                frameborder="0"
                scrolling="yes"
                marginheight="0"
                marginwidth="0" />

              <iframe v-if="showing === 'pdf'"
                :src="pdfsrc"
                frameborder="0"
                scrolling="yes"
                marginheight="0"
                marginwidth="0" />

            </div>

            <div v-else>
              <h3 class="text-left pa-4 ma-4">Não conseguimos obter informações sobre este documento.</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import Sidebar from './Sidebar.vue';

    import {
        prepareDate as utilPrepareDate,
    } from '@/utils/prepareTextAto.js';
    
    import { mdiOpenInNew } from '@mdi/js';

    export default {
        name: 'ItemDisplay',
        data: () => {
          return ({
            newtab: mdiOpenInNew,
            hist: false,
            showing: 'html',
            first: true,
            pdfsrc: null,
            pdfsrccons: null,
            renderhtml: null,
            renderhtmlcons: null
          })
        },
        props: {
            ato: Object,
        },
        components: {
            Sidebar,
        },
        computed: {
            prepareTitle: function () {
              return (this.ato)
                ? this.ato.descricao
                : "Ocorreu um problema...";
            },
            prepareDate: function () {
              return (this.ato)
                ? `de ${utilPrepareDate(this.ato.dataAto)}`
                : "";
            },
            downloadOtptionEnabled: function () {
                return this.pdfsrc ? !this.hasHtml && this.pdfsrc.length > 0 : false;
            },
            downloadConsOtptionEnabled: function () {
              return this.pdfsrccons ? !this.hasHtmlCons && this.pdfsrccons.length > 0 : false;
            },
            hasHtml: function () {
              return this.hasConteudo && this.ato.texto;
            },
            hasHtmlCons: function () {
              return this.hasConsolidado && this.ato.textoAtualizado;
            },
            hasConteudo: function () {
              return this.ato && (this.ato.texto ||  this.ato.arquivo);
            },
            hasConsolidado: function () {
              return this.ato && (this.ato.textoAtualizado ||  this.ato.arquivoAtualizado);
            },
            preparePath() {
              return `/html/constituicao/${this.ato.id}?atualizado=0`;
            },
            preparePathCons() {
              return `/html/constituicao/${this.ato.id}?atualizado=1`;
            },
        },
        methods: {
            changeShow: function (to) {
                if (to === 'pdf') this.showing = 'pdf';
                if (to === 'pdfc') this.showing = 'pdfc';
                if (to === 'html') this.showing = 'html';
                if (to === 'htmlc') this.showing = 'htmlc';
            },
            getSourceHtml: function () {
              if (this.hasConteudo)
                if (this.ato.texto) {
                  this.renderhtml = this.ato.texto;
                }
            },
            getSourcePDF: async function () {
                if (this.hasConteudo) {
                  const id = this.ato.id;
                  this.pdfsrc = `${this.$apiURL}/uploads/constituicao/${id}/${this.ato.arquivo}`;
                }
            },
            getSourceHtmlCons: function () {
              if (this.hasConsolidado)
                if (this.ato.textoAtualizado) {
                  this.renderhtmlcons = this.ato.textoAtualizado;
                }
            },
            getSourcePDFCons: async function () {
              if (this.hasConsolidado) {
                const id = this.ato.id;
                this.pdfsrccons = `${this.$apiURL}/uploads/constituicao/${id}/atualizado/${this.ato.arquivoAtualizado}`;
              }
            },
        },
        watch: {
          async ato() {
            this.getSourceHtml();
            this.getSourcePDF();
            this.getSourceHtmlCons();
            this.getSourcePDFCons();
            if (this.first) {
              if (this.hasConsolidado) {
                if (!this.hasHtmlCons) this.showing = 'pdfc';
                else this.showing = 'htmlc';
              }
              else if (this.hasConteudo) {
                if (!this.hasHtml) this.showing = 'pdf';
                else this.showing = 'html';
              }
              else this.showing = 'err';
             
              this.first = false;
            }
          },
        },
        async mounted() {
          this.getSourceHtmlCons();
          this.getSourcePDFCons();
          this.getSourceHtml();
          this.getSourcePDF();

          if (this.hasConsolidado) {
            if (!this.hasHtmlCons) this.showing = 'pdfc';
            else this.showing = 'htmlc';
          }
          else if (this.hasConteudo) {
            if (!this.hasHtml) this.showing = 'pdf';
            else this.showing = 'html';
          }
          else this.showing = 'err';
        }
    }
</script>

<style>
  @import './Display.css';
</style>
