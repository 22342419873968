<template>
    <v-card rounded="lg">
        <v-toolbar color="#0D8C1A"
                   class="d-block"
                   id="popupDialog"
                   dark
                   flat>
            
            <v-toolbar-title>Informações</v-toolbar-title>
            
            <v-btn icon v-on:click="$emit('close-dialog')">
                <v-icon>{{closeIcon}}</v-icon>
            </v-btn>
        </v-toolbar>
        <v-list-item id="listDialogInfo" five-line class="pt-4 flex-column align-items-start">
            <div v-for="info in infos" :key="info.title" class="mb-2">
                <v-card-title id="TitleInfoCard" class="text-left">{{info.title}}</v-card-title>
                <v-card-text id="TextInfoCard" class="text-left"><span style="white-space: pre-line">{{info.text}}</span></v-card-text>
            </div>
        </v-list-item>

</v-card>
</template>

<script>
    import { prepareDate } from '@/utils/prepareTextAto.js';
    import { mdiClose } from '@mdi/js';

    export default {
        name: 'DialogInfo',
        props: {
            ato: Object,
        },
        data: () => {
            return ({
                closeIcon: mdiClose,
                infos: [],
            })
        },
        mounted() {
            if (this.ato) {
                if (this.ato.ementa)
                  this.infos.push({ title: "Ementa", text: this.ato.ementa });

            
                var ato = this.ato;
                if (!ato.dataAto.toString().includes('0001'))
                  this.infos.push({ title: "Data do Ato", text: prepareDate(ato.dataAto) });

                /*if (this.ato) {
                  if (!this.ato.dataPublicacao.toString().includes('0001'))
                    this.infos.push({ title: "Data de Publicação", text: prepareDate(this.ato.dataPublicacao) });
                }*/

                if (ato.idIniciativa || ato.idSituacao) {
                  if (ato.iniciativa.descricao) this.infos.push({ title: "Iniciativa", text: ato.iniciativa.descricao});
                  if (ato.situacao.descricao) this.infos.push({ title: "Situacao", text: ato.situacao.descricao});
                }
            } else {
                this.infos = [{
                    title: "Algo deu errado",
                    text: "Estamos com alguns problemas para obter estas informações"
                }]
            }
        }
    }
</script>

<style>
    @import './DialogInfo.css';
</style>
